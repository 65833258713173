var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { "grid-width": "1/2", pageTitle: "Application Details" } },
    [
      _c(
        "vx-card",
        [
          _c(
            "vs-tabs",
            { staticClass: "tabs-shadow-none" },
            [
              _c(
                "vs-tab",
                {
                  staticClass: "fixed-height-app-edit",
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-settings",
                    label: "General Info",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex flex-wrap items-center mb-base mt-6" },
                    [
                      _c("vs-avatar", {
                        staticClass: "mr-4 mb-4 company-logo-avatar",
                        attrs: {
                          src: _vm.form.logoUrl,
                          icon: "cloud_upload",
                          size: "70px",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("vs-input", {
                    staticClass: "w-full mt-4",
                    attrs: { label: "Application Name*", disabled: "" },
                    model: {
                      value: _vm.form.applicationName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "applicationName", $$v)
                      },
                      expression: "form.applicationName",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "mt-4" },
                    [
                      _c("label", { staticClass: "text-sm" }, [
                        _vm._v("Web Platform"),
                      ]),
                      _c("v-select", {
                        attrs: {
                          value: _vm.selectedWebPlatform,
                          "append-to-body": "",
                          options: _vm.webPlatformOptions,
                          disabled: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mt-4" },
                    [
                      _c("label", { staticClass: "text-sm" }, [
                        _vm._v("Subscription*"),
                      ]),
                      _c("v-select", {
                        attrs: {
                          value: _vm.selectedSubscription,
                          "append-to-body": "",
                          options: _vm.subscriptionOptions,
                          disabled: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "flex items-center mt-4" },
                    [
                      _c("vs-switch", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.isDemo,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "isDemo", $$v)
                          },
                          expression: "form.isDemo",
                        },
                      }),
                      _c("span", { staticClass: "ml-4" }, [_vm._v("Is Demo")]),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "flex items-center mt-4" },
                    [
                      _c("vs-switch", {
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.isActive,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "isActive", $$v)
                          },
                          expression: "form.isActive",
                        },
                      }),
                      _c("span", { staticClass: "ml-4" }, [
                        _vm._v("Is Active"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "vs-tab",
                {
                  staticClass: "fixed-height-app-edit",
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-map-pin",
                    label: "Location",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "mt-4" },
                    [
                      _c("label", { staticClass: "text-sm" }, [
                        _vm._v("Region*"),
                      ]),
                      _c("v-select", {
                        attrs: {
                          value: _vm.selectedRegion,
                          "append-to-body": "",
                          options: _vm.regionOptions,
                          disabled: "",
                        },
                        on: { input: _vm.setSelectedRegion },
                      }),
                    ],
                    1
                  ),
                  _c("vs-input", {
                    staticClass: "w-full mt-4",
                    attrs: { label: "Address 1", disabled: "" },
                    model: {
                      value: _vm.form.address1,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "address1", $$v)
                      },
                      expression: "form.address1",
                    },
                  }),
                  _c("vs-input", {
                    staticClass: "w-full mt-4",
                    attrs: { label: "Address 2", disabled: "" },
                    model: {
                      value: _vm.form.address2,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "address2", $$v)
                      },
                      expression: "form.address2",
                    },
                  }),
                  _c("div", { staticClass: "vx-row" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full md:w-1/2" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full mt-4",
                          attrs: { label: "City", disabled: "" },
                          model: {
                            value: _vm.form.city,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "city", $$v)
                            },
                            expression: "form.city",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "vx-col w-full md:w-1/2" },
                      [
                        _c("vs-input", {
                          staticClass: "w-full mt-4",
                          attrs: { label: "State", disabled: "" },
                          model: {
                            value: _vm.form.state,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "state", $$v)
                            },
                            expression: "form.state",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "mt-4" },
                    [
                      _c("label", { staticClass: "text-sm" }, [
                        _vm._v("Country"),
                      ]),
                      _c("v-select", {
                        attrs: {
                          value: _vm.selectedCountry,
                          "append-to-body": "",
                          options: _vm.countryOptions,
                          disabled: "",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "vs-tab",
                {
                  staticClass: "fixed-height-app-edit",
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-calendar",
                    label: "Booking",
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c("vs-input", {
                        staticClass: "w-full mt-4",
                        attrs: { label: "Booking Email Name", disabled: "" },
                        model: {
                          value: _vm.form.bookingEmailName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "bookingEmailName", $$v)
                          },
                          expression: "form.bookingEmailName",
                        },
                      }),
                      _c("vs-input", {
                        staticClass: "w-full mt-4",
                        attrs: { label: "Booking Email", disabled: "" },
                        model: {
                          value: _vm.form.bookingEmail,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "bookingEmail", $$v)
                          },
                          expression: "form.bookingEmail",
                        },
                      }),
                      _c("vs-input", {
                        staticClass: "w-full mt-4",
                        attrs: {
                          label: "Booking Telephone Name",
                          disabled: "",
                        },
                        model: {
                          value: _vm.form.bookingTelephoneName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "bookingTelephoneName", $$v)
                          },
                          expression: "form.bookingTelephoneName",
                        },
                      }),
                      _c("vs-input", {
                        staticClass: "w-full mt-4 mb-8",
                        attrs: { label: "Booking Telephone", disabled: "" },
                        model: {
                          value: _vm.form.bookingTelephone,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "bookingTelephone", $$v)
                          },
                          expression: "form.bookingTelephone",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "vs-tab",
                {
                  staticClass: "fixed-height-app-edit",
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-info",
                    label: "Support",
                  },
                },
                [
                  _c("vs-input", {
                    staticClass: "w-full mt-4",
                    attrs: { label: "Support Email Name", disabled: "" },
                    model: {
                      value: _vm.form.supportEmailName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "supportEmailName", $$v)
                      },
                      expression: "form.supportEmailName",
                    },
                  }),
                  _c("vs-input", {
                    staticClass: "w-full mt-4",
                    attrs: { label: "Support Email", disabled: "" },
                    model: {
                      value: _vm.form.supportEmail,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "supportEmail", $$v)
                      },
                      expression: "form.supportEmail",
                    },
                  }),
                  _c("vs-input", {
                    staticClass: "w-full mt-4",
                    attrs: { label: "Support Telephone Name", disabled: "" },
                    model: {
                      value: _vm.form.supportTelephoneName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "supportTelephoneName", $$v)
                      },
                      expression: "form.supportTelephoneName",
                    },
                  }),
                  _c("vs-input", {
                    staticClass: "w-full mt-4",
                    attrs: { label: "Support Telephone", disabled: "" },
                    model: {
                      value: _vm.form.supportTelephone,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "supportTelephone", $$v)
                      },
                      expression: "form.supportTelephone",
                    },
                  }),
                ],
                1
              ),
              _c(
                "vs-tab",
                {
                  staticClass: "fixed-height-app-edit",
                  attrs: {
                    "icon-pack": "feather",
                    icon: "icon-code",
                    label: "API",
                  },
                },
                [
                  _c("div", { staticClass: "vx-row mt-4" }, [
                    _c("div", { staticClass: "vx-col sm:w-1/4 w-full" }, [
                      _c("span", [_vm._v("Allow Public API Key")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "vx-col sm:w-3/4 w-full" },
                      [
                        _c("vs-switch", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.form.allowPublicApiKey,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "allowPublicApiKey", $$v)
                            },
                            expression: "form.allowPublicApiKey",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm.form.allowPublicApiKey
                    ? _c("div", { staticClass: "vx-row mt-4" }, [
                        _c("div", { staticClass: "vx-col sm:w-1/4 w-full" }, [
                          _c("span", [_vm._v("Public API Key")]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "vx-col sm:w-3/4 w-full" },
                          [
                            _c("vs-input", {
                              staticClass: "w-full",
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.form.publicApiKey,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "publicApiKey", $$v)
                                },
                                expression: "form.publicApiKey",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.form.allowPublicApiKey
                    ? _c(
                        "div",
                        { staticClass: "flex flex-wrap justify-end" },
                        [
                          _c(
                            "vs-button",
                            {
                              directives: [
                                {
                                  name: "clipboard",
                                  rawName: "v-clipboard:copy",
                                  value: _vm.form.publicApiKey,
                                  expression: "form.publicApiKey",
                                  arg: "copy",
                                },
                                {
                                  name: "clipboard",
                                  rawName: "v-clipboard:success",
                                  value: _vm.onCopy,
                                  expression: "onCopy",
                                  arg: "success",
                                },
                              ],
                              staticClass: "mt-4",
                              attrs: {
                                "icon-pack": "feather",
                                icon: "icon-clipboard",
                                title: "Copy To Clipboard",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.isSmallerScreen ? "" : "Copy To Clipboard"
                                ) + "\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "vx-row mt-12" }, [
                    _c("div", { staticClass: "vx-col sm:w-1/4 w-full" }, [
                      _c("span", [_vm._v("Allow Private API Key")]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "vx-col sm:w-3/4 w-full" },
                      [
                        _c("vs-switch", {
                          attrs: { disabled: "" },
                          model: {
                            value: _vm.form.allowPrivateApiKey,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "allowPrivateApiKey", $$v)
                            },
                            expression: "form.allowPrivateApiKey",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm.form.allowPrivateApiKey
                    ? _c("div", { staticClass: "vx-row mt-12" }, [
                        _c("div", { staticClass: "vx-col sm:w-1/4 w-full" }, [
                          _c("span", [_vm._v("Private API Key")]),
                        ]),
                        _c(
                          "div",
                          { staticClass: "vx-col sm:w-3/4 w-full" },
                          [
                            _c("vs-input", {
                              staticClass: "w-full",
                              attrs: { disabled: "" },
                              model: {
                                value: _vm.form.privateApiKey,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "privateApiKey", $$v)
                                },
                                expression: "form.privateApiKey",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.form.allowPrivateApiKey
                    ? _c(
                        "div",
                        { staticClass: "flex flex-wrap justify-end" },
                        [
                          _c(
                            "vs-button",
                            {
                              directives: [
                                {
                                  name: "clipboard",
                                  rawName: "v-clipboard:copy",
                                  value: _vm.form.privateApiKey,
                                  expression: "form.privateApiKey",
                                  arg: "copy",
                                },
                                {
                                  name: "clipboard",
                                  rawName: "v-clipboard:success",
                                  value: _vm.onCopy,
                                  expression: "onCopy",
                                  arg: "success",
                                },
                              ],
                              staticClass: "mt-4",
                              attrs: {
                                title: "Copy To Clipboard",
                                "icon-pack": "feather",
                                icon: "icon-clipboard",
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.isSmallerScreen ? "" : "Copy To Clipboard"
                                ) + "\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "flex flex-wrap justify-end" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "mt-4 mr-4",
                  attrs: { type: "border", color: "danger" },
                  on: { click: _vm.handleCancel },
                },
                [_vm._v("Cancel")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }